type TDeclensionWordProps = {
  expressions: string[];
  amount?: number;
};

export const declensionWord = ({
  expressions,
  amount,
}: TDeclensionWordProps): string => {
  if (typeof amount !== 'number' || amount < 0) {
    return '';
  }

  const cases = [2, 0, 1, 1, 1, 2];

  return expressions[
    amount % 100 > 4 && amount % 100 < 20
      ? 2
      : cases[amount % 10 < 5 ? amount % 10 : 5]
  ];
};
