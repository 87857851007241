@if (link()) {
  <a
    [routerLink]="link()"
    [queryParams]="queries()"
    class="link-back"
    [class.absolute]="variety() === 'absolute'"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="24"
      viewBox="0 0 16 24"
      fill="none"
    >
      <path
        d="M7 6L1 12L7 18"
        stroke="#878787"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </a>
} @else {
  <button type="button" class="button-back">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="24"
      viewBox="0 0 16 24"
      fill="none"
    >
      <path
        d="M7 6L1 12L7 18"
        stroke="#878787"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
}
